/* line 89, scss/80-themes/Saunders/60-components/school-info.scss */
.c-school-info {
	position: relative;
	line-height: inherit;
}

/* line 94, scss/80-themes/Saunders/60-components/school-info.scss */
.schools-info__details {
	display: flex;
	flex-direction: column;
	justify-items: flex-start;
	background-color: rgb(var(--color-global-widget));
}

/* line 104, scss/80-themes/Saunders/60-components/school-info.scss */
.school-type__container, .school-level__container {
	max-width: 100%;
}

/* line 108, scss/80-themes/Saunders/60-components/school-info.scss */
.school-type__item, .school-level__item {
	display: inline-block;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	padding: 5px;
	max-width: 100%;
	box-sizing: border-box;
}

/* line 117, scss/80-themes/Saunders/60-components/school-info.scss */
.school-type__item > label, .school-level__item > label {
	display: block;
	cursor: pointer;
}

/* line 123, scss/80-themes/Saunders/60-components/school-info.scss */
.school-type__selector, .school-level__selector {
	width: auto;
}

/* line 129, scss/80-themes/Saunders/60-components/school-info.scss */
.school-type__container {
	flex: 1 1 auto;
}

/* line 134, scss/80-themes/Saunders/60-components/school-info.scss */
.school-type__item--public > label {
	background-color: #75c13a;
}

/* line 138, scss/80-themes/Saunders/60-components/school-info.scss */
.school-type__item--private > label {
	background-color: #71a6c8;
}

/* line 142, scss/80-themes/Saunders/60-components/school-info.scss */
.school-type__item--charter > label {
	background-color: #e7715d;
}

/* line 146, scss/80-themes/Saunders/60-components/school-info.scss */
.school-type__item > label {
	padding: 5px 10px;
}

/* line 150, scss/80-themes/Saunders/60-components/school-info.scss */
.school-type__item .checkbox__wrapper,
.school-type__item .checkbox__name {
	color: rgb(var(--color-global-positive));
}

/* line 155, scss/80-themes/Saunders/60-components/school-info.scss */
.school-type__item .checkbox__item {
	line-height: normal;
}

/* line 159, scss/80-themes/Saunders/60-components/school-info.scss */
.school-type__item .checkbox__name {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	margin: 0;
	max-width: 100%;
}

@media only screen and (max-width: 47.999em) {
	/* line 159, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-type__item .checkbox__name {
		font-size: 1.125em;
		line-height: 1.1108;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 159, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-type__item .checkbox__name {
		font-size: 1.125em;
		line-height: 1.222;
	}
}

@media only screen and (min-width: 80em) {
	/* line 159, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-type__item .checkbox__name {
		font-size: 1.125em;
		line-height: 1.222;
	}
}

/* line 168, scss/80-themes/Saunders/60-components/school-info.scss */
.school-level__container {
	flex: 0 1 auto;
}

@media only screen and (min-width: 48em) {
	/* line 168, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-level__container {
		text-align: right;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .school-level__container {
		text-align: left;
	}
}

@media only screen and (min-width: 48em) {
	/* line 176, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-level__item {
		padding-right: 10px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .school-level__item {
		padding-right: initial;
		padding-left: 10px;
	}
}

/* line 182, scss/80-themes/Saunders/60-components/school-info.scss */
.school-level__selector, .school-level__label {
	vertical-align: middle;
	cursor: pointer;
}

/* line 188, scss/80-themes/Saunders/60-components/school-info.scss */
.school-level__container .school-level__selector {
	width: auto;
}

/* line 192, scss/80-themes/Saunders/60-components/school-info.scss */
.school-level__label {
	padding-left: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .school-level__label {
	padding-left: initial;
	padding-right: 5px;
}

/* line 199, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__card-close {
	right: 0;
	display: none;
	position: absolute;
	padding: 15px;
	font-size: 1.5em;
	color: #709fb3;
	cursor: pointer;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .school-info__card-close {
	right: auto;
	left: 0;
}

@media only screen and (max-width: 79.999em) {
	/* line 199, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__card-close {
		display: block;
	}
}

/* line 213, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__card-name {
	font-size: 1.5em;
	font-weight: 400;
	margin: 0;
}

@media only screen and (max-width: 47.999em) {
	/* line 213, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__card-name {
		font-size: 1.05em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 213, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__card-name {
		font-size: 1.2495em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 213, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__card-name {
		font-size: 1.5em;
	}
}

/* line 220, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__result-map {
	margin-bottom: 0;
	height: 550px;
}

/* line 225, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__provider {
	text-align: right;
	vertical-align: middle;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .school-info__provider {
	text-align: left;
}

/* line 229, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__provider-description {
	font-family: var(--font-family-text);
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	margin-right: 10px;
	vertical-align: middle;
}

@media only screen and (max-width: 47.999em) {
	/* line 229, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__provider-description {
		font-size: 0.9em;
		line-height: 1.2992;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 229, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__provider-description {
		font-size: 0.9em;
		line-height: 1.4;
	}
}

@media only screen and (min-width: 80em) {
	/* line 229, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__provider-description {
		font-size: 0.9em;
		line-height: 1.4;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .school-info__provider-description {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .school-info__provider-description {
		margin-right: 0;
	}
}

/* line 235, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__provider-logo {
	display: inline-block;
	vertical-align: middle;
}

/* line 240, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__provider-link {
	display: block;
}

@media only screen and (max-width: 47.999em) {
	/* line 225, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__provider {
		padding-top: 20px;
		text-align: center;
	}
}

/* line 251, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__badge-icon {
	font-size: 200px;
	color: rgb(var(--color-global-border));
}

/* line 256, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__badge-value {
	left: 50%;
	top: 30%;
	position: absolute;
	color: rgb(var(--color-global-negative));
	font-size: 4.5em;
	transform: translate(-50%, -30%);
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .school-info__badge-value {
	left: auto;
	right: 50%;
}

/* line 266, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__participants, .school-info__enrolled-ratio {
	padding: 20px;
}

@media only screen and (max-width: 47.999em) {
	/* line 272, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__enrolled-ratio {
		text-align: center;
	}
	/* line 276, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__ethnicity-legend {
		padding-top: 20px;
	}
}

/* line 281, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__enrolled-students {
	vertical-align: middle;
}

/* line 284, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__enrolled-students-number {
	margin-right: 10px;
	color: rgb(var(--color-global-primary));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .school-info__enrolled-students-number {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .school-info__enrolled-students-number {
		margin-right: 0;
	}
}

/* line 289, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__enrolled-students-number, .school-info__enrolled-students-label {
	font-size: 1.2em;
}

@media only screen and (max-width: 47.999em) {
	/* line 281, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__enrolled-students {
		text-align: center;
	}
}

/* line 299, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__fixed-badge {
	right: 0;
	top: 0;
	position: absolute;
	width: 145px;
	margin-top: 20px;
	padding: 20px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .school-info__fixed-badge {
	right: auto;
	left: 0;
}

@media only screen and (max-width: 79.999em) {
	/* line 299, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__fixed-badge {
		position: relative;
		margin: 0 auto;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 299, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__fixed-badge {
		flex: 0 1 auto;
	}
}

/* line 317, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__contact-card {
	font-size: 1em;
	line-height: 1.375;
	padding: 20px;
}

@media only screen and (max-width: 47.999em) {
	/* line 317, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__contact-card {
		font-size: 1em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 317, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__contact-card {
		font-size: 1em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 317, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__contact-card {
		font-size: 1em;
	}
}

/* line 322, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__contact-card-title {
	border-bottom: 1px solid rgb(var(--color-global-positive));
}

@media only screen and (max-width: 47.999em) {
	/* line 326, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__contact-card-details {
		border-bottom: 1px solid rgb(var(--color-global-positive));
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 331, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__contact-card-details--with-badge {
		display: flex;
		flex-direction: row-reverse;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 339, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__contact-card--with-badge {
		flex: 1 1 auto;
	}
}

@media only screen and (min-width: 80em) {
	/* line 339, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__contact-card--with-badge {
		margin-right: 185px;
	}
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .school-info__contact-card--with-badge {
		margin-right: initial;
		margin-left: 185px;
	}
}

@media only screen and (min-width: 80em) and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .school-info__contact-card--with-badge {
		margin-right: 0;
	}
}

/* line 351, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__school-details {
	background-color: rgb(var(--color-global-widget));
}

@media only screen and (min-width: 30em) {
	/* line 354, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__school-details--with-badge {
		min-height: 240px;
	}
}

/* line 361, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__school-data {
	margin-bottom: 20px;
}

/* line 367, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__map-filters {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	background-color: rgb(var(--color-global-widget));
	padding: 5px;
}

/* line 377, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__captcha-background {
	max-width: 100%;
	height: auto;
	min-height: 600px;
}

@media only screen and (max-width: 47.999em) {
	/* line 377, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__captcha-background {
		min-height: 300px;
	}
}

/* line 387, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__captcha-container {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 800;
	display: inline-block;
	background-color: rgb(var(--color-global-positive));
	padding: 20px;
	box-shadow: 0 0 20px rgba(var(--color-global-negative), 0.35);
	position: absolute;
	width: 304px;
}

@media only screen and (max-width: 79.999em) {
	/* line 399, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-info__hide {
		display: none;
	}
}

/* line 405, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__rating {
	padding: 5px 0;
}

/* line 409, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__rating-color {
	height: 32px;
	width: 32px;
	border-radius: 100%;
	padding: 5px 0;
	color: rgb(var(--color-global-positive));
	box-sizing: border-box;
	text-align: center;
}

/* line 419, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__rating-color--1 {
	background-color: #e6e6e6;
}

/* line 419, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__rating-color--2 {
	background-color: #cccccc;
}

/* line 419, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__rating-color--3 {
	background-color: #b3b3b3;
}

/* line 419, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__rating-color--4 {
	background-color: #999999;
}

/* line 419, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__rating-color--5 {
	background-color: gray;
}

/* line 419, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__rating-color--6 {
	background-color: #666666;
}

/* line 419, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__rating-color--7 {
	background-color: #4d4d4d;
}

/* line 419, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__rating-color--8 {
	background-color: #333333;
}

/* line 419, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__rating-color--9 {
	background-color: #1a1a1a;
}

/* line 419, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__rating-color--10 {
	background-color: black;
}

/* line 425, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__section-title {
	padding: 40px 0 40px 0;
	text-align: center;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .school-info__section-title {
	padding: 40px 0 40px 0;
}

/* line 430, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__header-text {
	display: inline-block;
}

/* line 434, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__header-text, .school-info__header-icon {
	vertical-align: middle;
}

/* line 440, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .school-info__header-icon {
	font-size: 0.7em;
	font-weight: 700;
}

/* line 654, resources/80-themes/Saunders/fonts/_icons.scss */
.school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .school-info__header-icon:before {
	content: "\f11d";
}

/* line 654, resources/80-themes/Saunders/fonts/_icons.scss */
.school-info__header-icon-container--ascending .school-info__header-icon:before {
	content: "\f11c";
}

/* line 654, resources/80-themes/Saunders/fonts/_icons.scss */
.school-info__header-icon-container--descending .school-info__header-icon:before {
	content: "\f10d";
}

/* line 455, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__loadmore-button {
	margin-top: 60px;
}

/* line 459, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__disclaimer-container {
	text-align: center;
	font-size: 0.85em;
	font-weight: 500;
	margin: 60px 0;
}

/* line 466, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__disclaimer-data-source {
	margin-bottom: 10px;
}

/* line 470, scss/80-themes/Saunders/60-components/school-info.scss */
.school-info__participants {
	display: flex;
	align-items: center;
	flex: 1 0 auto;
	flex-wrap: wrap;
	border-top: 1px solid rgb(var(--color-global-positive));
}

/* line 479, scss/80-themes/Saunders/60-components/school-info.scss */
.school-details__link .icon, .school-details__link .school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .school-info__header-icon, .school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .school-details__link .school-info__header-icon, .school-details__link .school-info__header-icon-container--ascending .school-info__header-icon, .school-info__header-icon-container--ascending .school-details__link .school-info__header-icon, .school-details__link .school-info__header-icon-container--descending .school-info__header-icon, .school-info__header-icon-container--descending .school-details__link .school-info__header-icon {
	font-size: 1em;
	margin-left: 10px;
}

@media only screen and (max-width: 47.999em) {
	/* line 479, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-details__link .icon, .school-details__link .school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .school-info__header-icon, .school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .school-details__link .school-info__header-icon, .school-details__link .school-info__header-icon-container--ascending .school-info__header-icon, .school-info__header-icon-container--ascending .school-details__link .school-info__header-icon, .school-details__link .school-info__header-icon-container--descending .school-info__header-icon, .school-info__header-icon-container--descending .school-details__link .school-info__header-icon {
		font-size: 1em;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 479, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-details__link .icon, .school-details__link .school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .school-info__header-icon, .school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .school-details__link .school-info__header-icon, .school-details__link .school-info__header-icon-container--ascending .school-info__header-icon, .school-info__header-icon-container--ascending .school-details__link .school-info__header-icon, .school-details__link .school-info__header-icon-container--descending .school-info__header-icon, .school-info__header-icon-container--descending .school-details__link .school-info__header-icon {
		font-size: 1em;
	}
}

@media only screen and (min-width: 80em) {
	/* line 479, scss/80-themes/Saunders/60-components/school-info.scss */
	.school-details__link .icon, .school-details__link .school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .school-info__header-icon, .school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .school-details__link .school-info__header-icon, .school-details__link .school-info__header-icon-container--ascending .school-info__header-icon, .school-info__header-icon-container--ascending .school-details__link .school-info__header-icon, .school-details__link .school-info__header-icon-container--descending .school-info__header-icon, .school-info__header-icon-container--descending .school-details__link .school-info__header-icon {
		font-size: 1em;
	}
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .school-details__link .icon, .is-rtl .school-details__link .school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .school-info__header-icon, .school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .is-rtl .school-details__link .school-info__header-icon, .is-rtl .school-details__link .school-info__header-icon-container--ascending .school-info__header-icon, .school-info__header-icon-container--ascending .is-rtl .school-details__link .school-info__header-icon, .is-rtl .school-details__link .school-info__header-icon-container--descending .school-info__header-icon, .school-info__header-icon-container--descending .is-rtl .school-details__link .school-info__header-icon {
	margin-left: initial;
	margin-right: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .school-details__link .icon, .is-rtl .school-details__link .school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .school-info__header-icon, .school-info__header-icon-container:not(.school-info__header-icon-container--ascending):not(.school-info__header-icon-container--descending) .is-rtl .school-details__link .school-info__header-icon, .is-rtl .school-details__link .school-info__header-icon-container--ascending .school-info__header-icon, .school-info__header-icon-container--ascending .is-rtl .school-details__link .school-info__header-icon, .is-rtl .school-details__link .school-info__header-icon-container--descending .school-info__header-icon, .school-info__header-icon-container--descending .is-rtl .school-details__link .school-info__header-icon {
		margin-left: 0;
	}
}

/* line 487, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-chart__container {
	position: relative;
	height: 200px;
}

/* line 492, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-chart__background, .ethnicity-chart__item, .ethnicity-chart__content-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	border-radius: 100%;
}

/* line 504, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-chart__background, .ethnicity-chart__item {
	width: 200px;
	height: 200px;
}

/* line 510, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-chart__background {
	box-shadow: 0 0 8px rgba(var(--color-global-negative), 0.5);
}

/* line 514, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-chart__item {
	clip: rect(0, 200px, 200px, 100px);
}

/* line 517, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-chart__item:nth-child(2) {
	z-index: 1;
}

/* line 523, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-chart__content-wrap {
	z-index: 1;
	display: flex;
	width: 120px;
	height: 120px;
	background-color: rgb(var(--color-global-widget));
	align-items: center;
}

/* line 532, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-chart__content-inner {
	font-family: var(--font-family-headings);
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	display: flex;
	margin: auto;
	text-align: center;
}

@media only screen and (max-width: 47.999em) {
	/* line 532, scss/80-themes/Saunders/60-components/school-info.scss */
	.ethnicity-chart__content-inner {
		font-size: 1.125em;
		line-height: 1.1108;
	}
}

@media only screen and (min-width: 48em) and (max-width: 79.999em) {
	/* line 532, scss/80-themes/Saunders/60-components/school-info.scss */
	.ethnicity-chart__content-inner {
		font-size: 1.125em;
		line-height: 1.222;
	}
}

@media only screen and (min-width: 80em) {
	/* line 532, scss/80-themes/Saunders/60-components/school-info.scss */
	.ethnicity-chart__content-inner {
		font-size: 1.125em;
		line-height: 1.222;
	}
}

/* line 542, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__value {
	margin-right: 4px;
	color: rgb(var(--color-global-negative));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .ethnicity-item__value {
	margin-right: initial;
	margin-left: 4px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .ethnicity-item__value {
		margin-right: 0;
	}
}

/* line 548, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-chart {
	border-radius: 100%;
	position: absolute;
	width: 200px;
	height: 200px;
	clip: rect(0, 100px, 201px, 0);
	transition: all 1s;
}

/* line 557, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-legend {
	margin-right: 10px;
	width: 10px;
	height: 10px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .ethnicity-item__color-legend {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .ethnicity-item__color-legend {
		margin-right: 0;
	}
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-1 {
	background-color: #69b684;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-2 {
	background-color: #6cbfb5;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-3 {
	background-color: #f6c769;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-4 {
	background-color: #e7715d;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-5 {
	background-color: #ef975b;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-6 {
	background-color: #c7d86f;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-7 {
	background-color: #5d9ee7;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-8 {
	background-color: #33577f;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-9 {
	background-color: #a2b23f;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-10 {
	background-color: #ff8a80;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-11 {
	background-color: #9a3e2f;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-12 {
	background-color: #a483c2;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-13 {
	background-color: #35726b;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-14 {
	background-color: #8c7d41;
}

/* line 564, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__color-15 {
	background-color: #ccba9e;
}

/* line 570, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-item__section {
	display: inline-block;
}

/* line 575, scss/80-themes/Saunders/60-components/school-info.scss */
.ethnicity-legend__item {
	margin: 5px 0;
}

/*# sourceMappingURL=../../../../true */